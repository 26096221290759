import Vue from 'vue'
import App from './App.vue'
import 'vue-material-design-icons/styles.css'
import router from './router'
import axios from 'axios'  
import VueAxios from 'vue-axios'

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.SERVER_URL
axios.defaults.baseURL = '/api/';
//axios.defaults.baseURL = 'http://localhost/api/';
axios.defaults.headers.common['Session-Id'] = getCookie('Session-Id');
Vue.use(VueAxios, axios);

 new Vue({
  router,
  render: h => h(App),
  data: {
    session: {name: ''}
  }
}).$mount('#app');
